import Link from 'next/link'
import styles from './AppMenu.module.scss'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { useSession } from 'next-auth/react'
import { ConnectWallet } from '@thirdweb-dev/react'
import { MobileMenu } from '~/components/AppMenu/MobileMenu'
import { useRouter } from 'next/router'
import { DesktopMenu } from '~/components/AppMenu/DesktopMenu'
import { signIn } from '~/modules/Auth/Infrastructure/SignIn'

export const AppMenu: FC = () => {
  const { t } = useTranslation('app_menu')
  const session = useSession()
  const [openMenu, setOpenMenu] = useState(false)
  const router = useRouter()
  let myTrophiesLinkHref = '/my-collection'
  let userAvatar = null
  let loginButton = null

  const walletButton = (
    <div className={ styles.appMenu__connectWalletButton }>
      <ConnectWallet btnTitle={ t('connect_wallet_button') } />
    </div>
  )

  if (
    session.status === 'unauthenticated' &&
    router.pathname !== '/auth/signin'
  ) {
    myTrophiesLinkHref = '/auth/signin?callbackUrl=/my-collection'

    loginButton = (
      <button
        className={ styles.appMenu__loginButton }
        onClick={ () => signIn(router) }
      >
        { t('login_button_label') }
      </button>
    )
  }

  if (session.status === 'authenticated') {
    const user = session.data.user

    userAvatar = (
      <object
        data={ user.image ?? undefined }
        className={ styles.appMenu__userAvatar }
        onClick={ () => setOpenMenu(!openMenu) }
      >
        <img
          className={ styles.appMenu__userAvatarDefault }
          src={ '/img/defaults/user.png' }
          alt={ t('user_avatar_alt', { name: user.name }) }
        />
      </object>
    )
  }

  return (
    <nav className={ styles.appMenu__navbar }>
      <div className={ styles.appMenu__container }>
        <Link href="/" className={ styles.appMenu__logoImage } passHref>
          <img
            src="/brand-logo.svg"
            alt="Legend Museum"
          />
        </Link>

        <div className={ styles.appMenu__centerActions }>
          <Link
            href="/#featured-organizers"
            className={ `
              ${styles.appMenu__centerActionsLink}
            ` }
          >
            { t('organizers_section') }
          </Link>

          <Link
            href={ myTrophiesLinkHref }
            className={ `
              ${styles.appMenu__centerActionsLink}
              ${router.pathname === '/my-collection' ? styles.appMenu__centerActionsLink_active : ''}
            ` }
          >
            { t('my_collection_section') }
          </Link>
        </div>

        <div className={ styles.appMenu__rightActions }>
          { walletButton }

          { userAvatar }

          <DesktopMenu
            openMenu={ openMenu }
            setOpenMenu={ setOpenMenu }
          />

          { loginButton }

          <button
            title={ t('open_menu_button') }
            className={ styles.appMenu__openMobileMenuButton }
            onClick={ () => setOpenMenu(true) }
          >
            <FontAwesomeIcon
              icon={ faBars }
            />
          </button>

          <MobileMenu
            openMenu={ openMenu }
            setOpenMenu={ setOpenMenu }
          />
        </div>
      </div>
    </nav>
  )
}
