import { NextRouter } from 'next/router'
import { signOut } from 'next-auth/react'

export async function logOut (router: NextRouter) {
  const currentRoute = router.asPath

  await signOut({ redirect: false })

  if (currentRoute.startsWith('/my-collection')) {
    await router.push(`/${router.locale}`)
  }
}
