import { NextRouter } from 'next/router'

export async function signIn (router: NextRouter) {
  let callbackUrl = router.asPath

  if (
    callbackUrl === '/auth/error' ||
    callbackUrl === '/auth/verify-request'
  ) {
    callbackUrl = router.query.callbackUrl ? router.query.callbackUrl.toString() : '/'
  }

  if (callbackUrl !== '/auth/signin') {
    await router.push(`/${router.locale}/auth/signin?callbackUrl=${callbackUrl}`)
  }
}
