import styles from './AppFooter.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTelegram,
  faXTwitter,
  faMedium,
  faLinkedinIn,
  faInstagram,
  faYoutube,
  faDiscord
} from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'next-i18next'
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'
import Link from 'next/link'

export const AppFooter: FC = () => {
  const { t } = useTranslation('footer')

  return (
    <footer className={ styles.appFooter__container }>
      <div className={ styles.appFooter__contentContainer }>
        <div className={ styles.appFooter__platform }>
          <Link
            className={ styles.appFooter__platform__item }
            href="https://factory.belegends.com/"
            target="_blank"
          >
            Legend Factory
          </Link>

          <Link
            className={ styles.appFooter__platform__item }
            href="https://market.belegends.com/"
            target="_blank"
          >
            Legend Market
          </Link>

          <Link
            className={ styles.appFooter__platform__item }
            href="https://verify.belegends.com/"
            target="_blank"
          >
            Legend Verify
          </Link>
        </div>

        <div className={ styles.appFooter__socialNetworksList }>
          <a
            className={ styles.appFooter__socialNetworkLink }
            href="https://leverade.medium.com"
            target="_blank"
            rel="noreferrer"
            title="Medium"
          >
            <FontAwesomeIcon
              className="fa-fw"
              icon={ faMedium }
            />
          </a>

          <a
            className={ styles.appFooter__socialNetworkLink }
            href="https://t.me/belegends_ok"
            target="_blank"
            rel="noreferrer"
            title="Telegram"
          >
            <FontAwesomeIcon
              className="fa-fw"
              icon={ faTelegram }
            />
          </a>

          <a
            className={ styles.appFooter__socialNetworkLink }
            href="https://www.linkedin.com/company/leverade"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn"
          >
            <FontAwesomeIcon
              className="fa-fw"
              icon={ faLinkedinIn }
            />
          </a>

          <a
            className={ styles.appFooter__socialNetworkLink }
            href="https://www.youtube.com/@leverade6858"
            target="_blank"
            rel="noreferrer"
            title="YouTube"
          >
            <FontAwesomeIcon
              className="fa-fw"
              icon={ faYoutube }
            />
          </a>

          <a
            className={ styles.appFooter__socialNetworkLink }
            href="https://www.instagram.com/belegends_ok/"
            target="_blank"
            rel="noreferrer"
            title="Instagram"
          >
            <FontAwesomeIcon
              className="fa-fw"
              icon={ faInstagram }
            />
          </a>

          <a
            className={ styles.appFooter__socialNetworkLink }
            href="https://x.com/belegends_ok"
            target="_blank"
            rel="noreferrer"
            title="Twitter"
          >
            <FontAwesomeIcon
              className="fa-fw"
              icon={ faXTwitter }
            />
          </a>

          <a
            className={ styles.appFooter__socialNetworkLink }
            href="https://discord.com/invite/PUaF4NFFwa"
            target="_blank"
            rel="noreferrer"
            title="Discord"
          >
            <FontAwesomeIcon
              className="fa-fw"
              icon={ faDiscord }
            />
          </a>
        </div>

        <a
          className={ styles.appFooter__copyright }
          href="https://belegends.com"
          target="_blank"
          rel="noreferrer"
        >
          { t('copyright') }
        </a>

        <div className={ styles.appFooter__contact }>
          <Link
            className={ styles.appFooter__contact__item }
            href='/faq'
          >
            FAQ
          </Link>

          <a
            className={ styles.appFooter__contact__item }
            href="mailto:support@belegends.com"
            target="_blank"
            rel="noreferrer"
          >
            { t('contact_link') }

            <FontAwesomeIcon
              className={ styles.appFooter__contactIconLink }
              icon={ faArrowUpRight }
            />
          </a>
        </div>
      </div>
    </footer>
  )
}
