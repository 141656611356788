import { AnalyticsServiceInterface } from '~/modules/Analytics/Domain/AnalyticsServiceInterface'
import { IdentifyAnalyticsDto } from '~/modules/Analytics/Domain/IdentifyAnalyticsDto'
import {
  TrackCheckoutStartedDto, TrackProductMemoryCreatedDto, TrackProductMemoryRemovedDto, TrackProductMintedDto,
  TrackProductPurchasedDto, TrackProductSharedDto, TrackProductViewedDto
} from '~/modules/Analytics/Domain/TrackAnalyticsDto'
import { TrackGalleryDto } from '~/modules/Analytics/Domain/TrackGalleryDto'

export class SegmentAnalyticsService implements AnalyticsServiceInterface {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private segment: any

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor (segment: any) {
    this.segment = segment
  }

  async identify (identifyDto: IdentifyAnalyticsDto): Promise<void> {
    try {
      await this.segment.identify(identifyDto.id, {
        email: identifyDto.email,
        name: identifyDto.name,
        createdAt: identifyDto.createdAt,
      })
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackCheckoutStarted (trackDto: TrackCheckoutStartedDto): Promise<void> {
    try {
      await this.segment.track('Checkout Started', trackDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackProductPurchased (trackDto: TrackProductPurchasedDto): Promise<void> {
    try {
      await this.segment.track('Product Purchased', trackDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackProductShared (trackDto: TrackProductSharedDto): Promise<void> {
    try {
      await this.segment.track('Product Shared', trackDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackProductViewed (viewedDto: TrackProductViewedDto): Promise<void> {
    try {
      await this.segment.track('Product Viewed', viewedDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackProductMinted (mintedDto: TrackProductMintedDto): Promise<void> {
    try {
      await this.segment.track('Product Minted', mintedDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackProductMemoryRemoved (trackDto: TrackProductMemoryRemovedDto): Promise<void> {
    try {
      await this.segment.track('Memory Removed', trackDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackProductMemoryCreated (trackDto: TrackProductMemoryCreatedDto): Promise<void> {
    try {
      await this.segment.track('Memory Created', trackDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackGalleryEnabled (galleryDto: TrackGalleryDto): Promise<void> {
    try {
      await this.segment.track('Gallery Enabled', galleryDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackGalleryDisabled (galleryDto: TrackGalleryDto): Promise<void> {
    try {
      await this.segment.track('Gallery Disabled', galleryDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }

  async trackGalleryViewed (galleryDto: TrackGalleryDto): Promise<void> {
    try {
      await this.segment.track('Gallery Viewed', galleryDto)
    } catch (exception: unknown) {
      console.error(exception)
    }
  }
}
