import { Dispatch, FC, SetStateAction } from 'react'
import styles from './DesktopMenu.module.scss'
import { useTranslation } from 'next-i18next'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { logOut } from '~/modules/Auth/Infrastructure/SignOut'

interface Props {
  openMenu: boolean
  setOpenMenu: Dispatch<SetStateAction<boolean>>
}

export const DesktopMenu: FC<Props> = ({ openMenu, setOpenMenu }) => {
  const { t } = useTranslation('app_menu')
  const router = useRouter()
  const session = useSession()

  let myTrophiesLinkHref = '/my-collection'
  let myProfileLinkHref = '/profile'

  if (session.status === 'unauthenticated') {
    myTrophiesLinkHref = '/auth/signin?callbackUrl=/my-collection'
    myProfileLinkHref = '/auth/signin?callbackUrl=/profile'
  }

  return (
      <div
        className={ `
          ${styles.desktopMenu__dropdown}
          ${openMenu ? styles.desktopMenu__dropdown_open : ''}
        ` }
        onMouseLeave={ () => setOpenMenu(false) }
        onClick={ () => setOpenMenu(false) }
      >
        <Link href={ myProfileLinkHref } className={ styles.desktopMenu__dropdownButton }>
          { t('my_profile_section') }
        </Link>

        <Link href={ myTrophiesLinkHref } className={ styles.desktopMenu__dropdownButton }>
          { t('my_collection_section') }
        </Link>

        <button
          className={ styles.desktopMenu__dropdownButton }
          onClick={ async () => logOut(router) }
        >
          { t('logout_button_label') }
        </button>
      </div>
  )
}
