import { FC, PropsWithChildren, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import styles from './UserTosModal.module.scss'
import { Modal } from '~/modules/Shared/Infrastructure/Components/Modal'
import { logOut } from '~/modules/Auth/Infrastructure/SignOut'
import { useRouter } from 'next/router'
import Link, { LinkProps } from 'next/link'
import { useUserContext } from '~/hooks/UserContext'

export const UserTosModal: FC = () => {
  const { t } = useTranslation('user_tos')
  const [isOpen, setIsOpen] = useState(() => false)
  const { user, updateUser } = useUserContext()
  const router = useRouter()
  const locale = router.locale ?? 'en'

  // TODO: Update ToS links & Spanish version when available
  let linkToToS = 'https://drive.google.com/file/d/1sbosOyjuncrQBT1LelXwkaZmEUEhEHZK/view'
  let linkToPrivacyPolitic = 'https://drive.google.com/file/d/1sbosOyjuncrQBT1LelXwkaZmEUEhEHZK/view'

  if (locale === 'es') {
    linkToToS = 'https://drive.google.com/file/d/1sbosOyjuncrQBT1LelXwkaZmEUEhEHZK/view'
    linkToPrivacyPolitic = 'https://drive.google.com/file/d/1sbosOyjuncrQBT1LelXwkaZmEUEhEHZK/view'
  }

  if (isOpen) {
    if (user === null || user.tosAccepted) {
      setIsOpen(false)
    }
  } else {
    if (user !== null && !user.tosAccepted) {
      setIsOpen(true)
    }
  }

  const onClose = async () => {
    await logOut(router)
  }

  const onAcceptTos = async () => {
    try {
      await fetch(`/api/users/${user?.id}/accept`, {
        method: 'POST',
      })

      updateUser()
    } catch (exception: unknown) {
      console.log(exception)
    }
  }

  const LinkText = (props: PropsWithChildren<LinkProps>) => {
    return (
      <Link
        { ...props }
        href={ props.href || '' }
        className={ styles.userTosModal__highlightedText }
        target="_blank"
        rel="noreferrer"
      >
        { props.children }
      </Link>
    )
  }

  const linksTosPrivacy = (
    <Trans
      i18nKey={ t('accept_tos_modal_message') }
      components={ [
        <LinkText href={ linkToToS } key={ linkToToS } />,
        <LinkText href={ linkToPrivacyPolitic } key={ linkToPrivacyPolitic } />,
      ] }
    />
  )

  return (
    <Modal
      isOpen={ isOpen }
      onClose={ undefined }
    >
      <span
        data-testid={ isOpen ? 'user-tos-modal-container-open' : 'user-tos-modal-container-close' }
        className={ styles.userTosModal__container }
      >
        <img
          className={ styles.userTosModal__logo }
          src='/img/defaults/leverade-logo.svg'
          alt="Legend Museum"
        />
        <span className={ styles.userTosModal__messageContainer }>
          <p className={ styles.userTosModal__messageTitle }>
            { t('welcome_tos_modal_message') }
          </p>
          <p className={ styles.userTosModal__messageContent }>
            { linksTosPrivacy }
          </p>
        </span>

        <button
          className={ styles.userTosModal__button }
          onClick={ onAcceptTos }
          type='button'
        >
          { t('accept_tos_modal_button') }
        </button>
        <span
          className={ styles.userTosModal__highlightedText }
          onClick={ onClose }
        >
          { t('decline_tos_modal_message') }
        </span>
      </span>
    </Modal>
  )
}
