const defaultTheme = require('tailwindcss/defaultTheme')

function classNamesToZIndex (classNames) {
  return classNames.reduce((zIndexes, className, index) => {
    zIndexes[className] = index + 1

    return zIndexes
  }, {})
}

const containerPaddingDefault = '1.25rem'
const containerPaddingSmall = '2rem'

module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    fontFamily: {
      sans: ['"Space Grotesk"', ...defaultTheme.fontFamily.sans],
    },
    fontSize: {
      /* [ font-size, line-height ] */
      xs: ['0.625rem', '0.8125rem'], // 10px, 13px
      sm: ['0.6875rem', '0.96875rem'], // 11px, 15.5px
      base: ['0.75rem', '1.125rem'], // 12px, 18px
      lg: ['0.8125rem', '1.25rem'], // 13px, 20px
      xl: ['0.875rem', '1.25rem'], // 14px, 20px
      '2xl': ['1rem', '1.375rem'], // 16px, 22px
      '3xl': ['1.125rem', '1.40625rem'], // 18px, 22.5px
      '4xl': ['1.25rem', '1.4375rem'], // 20px, 23px
      '5xl': ['1.5rem', '1'], // 24px, 24px
      '6xl': ['1.875rem', '1'], // 30px, 30px
      '7xl': ['2rem', '1'], // 32px, 32px
      '8xl': ['3rem', '1'], // 48px, 48px
      '9xl': ['5rem', '1'], // 80px, 80px
    },
    lineHeight: {
      none: 1,
      tighter: 1.125,
      tight: 1.25,
      snug: 1.375,
      normal: 1.5,
      relaxed: 1.625,
      loose: 2,
    },
    colors: {
      brand: {
        200: '#fff9cc',
        300: '#fff06b',
        400: '#ffe408',
        500: '#daff02', // Accent
        600: '#b4d623',
        700: '#8c9900',
        800: '#5e6600',
      },
      base: {
        50: '#ffffff',
        100: '#f5f5f5', // White
        200: '#c6c6c6',
        250: '#838383',
        300: '#7c7c7c', // Gray
        400: '#5b5b5b',
        500: '#393939',
        550: '#373737',
        600: '#232522', // Black 2
        700: '#1a1a1a',
        800: '#101010', // Black 1
        '800/70': '#101010b2',
        '800/75': '#101010bf',
        '800/80': '#101010cc',
        900: '#0a0a0a',
      },
      success: {
        500: '#02d93e',
      },
      danger: {
        500: '#ff102d',
      },
      white: '#ffffff',
      black: '#0a0a0a',
      // todo: find a name for this color
      ffrm: '#ff0000',
      transparent: 'transparent',
    },
    zIndex: classNamesToZIndex([
      'card-reveal-card',
      'card-reveal-booster',
      'card-reveal-ui',
      'trophy-card-discipline',
      'trophy-card-link',
      'card-card-link',
      'mint-button',
      'featured-trophy-card-link',
      'featured-organizer-card-link',
      'app-menu',
      'trophy-card-lightbox',
      'backdrop',
      'web3-modal',
    ]),
    container: {
      padding: {
        DEFAULT: containerPaddingDefault,
        sm: containerPaddingSmall,
        md: '4rem',
        lg: '6rem',
        xl: '8rem',
      },
    },
    extend: {
      animation: {
        tilt: 'tilt 4s ease-in-out infinite',
        'hover-tilt': 'hover-tilt 4s ease-in-out infinite 0.5s',
      },
      keyframes: {
        tilt: {
          '0%': {
            transform: 'rotate3d(0.2, 1, 0.2, 15deg)',
            filter: 'brightness(100%)',
          },
          '25%': {
            filter: 'brightness(125%)',
          },
          '50%': {
            transform: 'rotate3d(0.2, -1, -0.2, 15deg)',
            filter: 'brightness(100%)',
          },
          '75%': {
            filter: 'brightness(125%)',
          },
          '100%': {
            transform: 'rotate3d(0.2, 1, 0.2, 15deg)',
            filter: 'brightness(100%)',
          },
        },
        'hover-tilt': {
          '0%': {
            transform: 'scale(1.1) rotate3d(0.2, 1, 0.2, 15deg)',
            filter: 'brightness(100%)',
          },
          '25%': {
            filter: 'brightness(125%)',
          },
          '50%': {
            transform: 'scale(1.1) rotate3d(0.2, -1, -0.2, 15deg)',
            filter: 'brightness(100%)',
          },
          '75%': {
            filter: 'brightness(125%)',
          },
          '100%': {
            transform: 'scale(1.1) rotate3d(0.2, 1, 0.2, 15deg)',
            filter: 'brightness(100%)',
          },
        },
      },
      transitionDuration: {
        DEFAULT: '300ms',
      },
      spacing: {
        container: containerPaddingDefault,
        'container-sm': containerPaddingSmall,
        18: '4.5rem',
      },
    },
  },
  plugins: [],
}
