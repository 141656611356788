import { FC, useEffect, useState } from 'react'
import { SegmentAnalyticsService } from '~/modules/Analytics/Infrastructure/SegmentAnalyticsService'
import { useSession } from 'next-auth/react'

/**
 * React Component to identify in Segment a logged-in user
 *
 * NOTE:
 *  This component feels a bit artificial. A more natural solution would imply a server-side call to
 *  segmentService.identify(...) from this callback: https://next-auth.js.org/configuration/callbacks#sign-in-callback
 */
export const SegmentIdentify: FC = () => {
  const session = useSession()
  const [isIdentified, setIsIdentified] = useState<boolean>(false)

  useEffect(() => {
    async function identify () {
      if (window.analytics !== undefined && session.status === 'authenticated' && !isIdentified) {
        setIsIdentified(true)
        const user = session.data.user
        const segmentService = new SegmentAnalyticsService(window.analytics)

        if (user.email && user.name) {
          await segmentService.identify({
            id: user.id,
            name: user.name,
            email: user.email,
            createdAt: user.createdAt ?? '',
          })
        }
      }
    }

    identify()
  }, [session, isIdentified])

  return (<></>)
}
