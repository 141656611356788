import { Dispatch, FC, SetStateAction } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './MobileMenu.module.scss'
import TailwindConfig from '~/tailwind.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useSession } from 'next-auth/react'
import { signIn } from '~/modules/Auth/Infrastructure/SignIn'
import { logOut } from '~/modules/Auth/Infrastructure/SignOut'

interface Props {
  openMenu: boolean
  setOpenMenu: Dispatch<SetStateAction<boolean>>
}

export const MobileMenu: FC<Props> = ({ openMenu, setOpenMenu }) => {
  const { status } = useSession()
  const router = useRouter()
  const { t } = useTranslation('app_menu')

  let logoutButton
  let loginButton
  let myTrophiesLinkHref = '/my-collection'
  let myProfileLinkHref = '/profile'

  if (
    status === 'unauthenticated' &&
    router.pathname !== '/auth/signin'
  ) {
    myTrophiesLinkHref = '/auth/signin?callbackUrl=/my-collection'
    myProfileLinkHref = '/auth/signin?callbackUrl=/profile'

    loginButton = (
      <button
        className={ styles.mobileMenu__loginButton }
        onClick={ () => signIn(router) }
      >
        { t('login_button_label') }
      </button>
    )
  }

  if (status === 'authenticated') {
    logoutButton = (
      <button
        className={ styles.mobileMenu__bottomNavigationLink }
        onClick={ async () => logOut(router) }
      >
        { t('logout_button_label') }
      </button>
    )
  }

  return (
    <CSSTransition
      classNames={ {
        enter: styles.mobileMenu__backdropEnter,
        enterActive: styles.mobileMenu__backdropEnterActive,
        enterDone: styles.mobileMenu__backdropEnterDone,
        exit: styles.mobileMenu__backdropExit,
        exitActive: styles.mobileMenu__backdropExitActive,
        exitDone: styles.mobileMenu__backdropExitDone,
      } }
      in={ openMenu }
      timeout={ parseInt(TailwindConfig.theme.extend.transitionDuration.DEFAULT) }
    >
      <div
        className={ styles.mobileMenu__backdrop }
        onClick={ () => setOpenMenu(false) }
      >
        <CSSTransition
          classNames={ {
            enterActive: styles.mobileMenu__slideOutEnterActive,
            enterDone: styles.mobileMenu__slideOutEnterDone,
            exit: styles.mobileMenu__slideOutExit,
            exitActive: styles.mobileMenu__slideOutExitActive,
          } }
          in={ openMenu }
          timeout={ parseInt(TailwindConfig.theme.extend.transitionDuration.DEFAULT) }
        >
          <div className={ styles.mobileMenu__slideOut }>
            <div className={ styles.mobileMenu__slideOutHeader }>
              <button
                title={ t('close_slide_out_button') }
                className={ styles.mobileMenu__closeMenuButton }
                onClick={ () => setOpenMenu(false) }
              >
                <FontAwesomeIcon
                  icon={ faXmark }
                />
              </button>
            </div>

            <div className={ styles.mobileMenu__topNavigation }>
              <Link
                href="/"
                className={ `
                  ${styles.mobileMenu__topNavigationLink}
                  ${router.pathname === '/' ? styles.mobileMenu__topNavigationLink_active : ''}
                ` }
              >
                { t('home_section') }
              </Link>

            <Link
              href="/#featured-organizers"
              className={ `
                ${styles.mobileMenu__topNavigationLink}
              ` }
            >
              { t('organizers_section') }
            </Link>

            <Link
              href={ myProfileLinkHref }
              className={ `
                ${styles.mobileMenu__topNavigationLink}
                ${router.pathname === '/profile' ? styles.mobileMenu__topNavigationLink_active : ''}
              ` }
            >
                { t('my_profile_section') }
            </Link>

            <Link
              href={ myTrophiesLinkHref }
              className={ `
                ${styles.mobileMenu__topNavigationLink}
                ${router.pathname === '/my-collection' ? styles.mobileMenu__topNavigationLink_active : ''}
              ` }
            >
                { t('my_collection_section') }
            </Link>
            </div>

            <div className={ styles.mobileMenu__bottomNavigation }>
              { logoutButton }
              { loginButton }
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  )
}
