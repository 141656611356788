import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import styles from './Modal.module.scss'
import TailwindConfig from '~/tailwind.config'
import { CSSTransition } from 'react-transition-group'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Callback {
  (): void
}

interface Props {
  isOpen: boolean
  onClose: Callback | undefined
}

export const Modal: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose = undefined,
  children,
}) => {
  const [isIn, setIsIn] = useState<boolean>(false)

  // FIXME: Workaround to allow popups to be created directly open
  useEffect(() => {
    setTimeout(() => {
      setIsIn(isOpen)
    }, 1)
  }, [isOpen])

  return (
      <CSSTransition
        data-qa='modal-backdrop'
        classNames={ {
          enter: styles.modal__modalBackdropEnter,
          enterActive: styles.modal__modalBackdropEnterActive,
          enterDone: styles.modal__modalBackdropEnterDone,
          exit: styles.modal__modalBackdropExit,
          exitActive: styles.modal__modalBackdropExitActive,
          exitDone: styles.modal__modalBackdropExitDone,
        } }
        in={ isIn }
        timeout={ parseInt(TailwindConfig.theme.extend.transitionDuration.DEFAULT) }
      >
        <div
          className={ styles.modal__modalBackdrop }
          onClick={ onClose }
        >
          <CSSTransition
            classNames={ {
              enter: styles.modal__containerEnter,
              enterActive: styles.modal__containerEnterActive,
              enterDone: styles.modal__containerEnterDone,
              exit: styles.modal__containerExit,
              exitActive: styles.modal__containerExitActive,
              exitDone: styles.modal__containerExitDone,
            } }
            in={ isIn }
            timeout={ parseInt(TailwindConfig.theme.extend.transitionDuration.DEFAULT) }
          >
            <section
              className={ styles.modal__container }
              onClick={ (event) => event.stopPropagation() }
            >
              {
                onClose &&
                <FontAwesomeIcon
                  icon={ faXmark }
                  className={ styles.modal__closeModalButton }
                  onClick={ onClose }
                />
              }

              { children }
            </section>
          </CSSTransition>
        </div>
      </CSSTransition>
  )
}
