import { UserApplicationDto, UserApplicationPublicDto } from '~/modules/Auth/Application/GetUser/UserApplicationDto'
import { UserProviderUserDto } from '~/modules/Auth/Infrastructure/Dtos/UserProviderUserDto'
import { DateTime } from 'luxon'

export class UserProviderUserDtoTranslator {
  public static publicFromApplication (applicationDto: UserApplicationPublicDto): UserProviderUserDto {
    let createdAt: string | null = null

    if (applicationDto.createdAt !== null) {
      createdAt = DateTime.fromISO(applicationDto.createdAt)
        .toLocaleString({ month: 'long', year: 'numeric' })
    }

    return {
      id: applicationDto.id,
      name: applicationDto.name,
      email: '',
      createdAt: createdAt,
      emailVerified: applicationDto.emailVerified,
      image: applicationDto.image,
      tosAccepted: applicationDto.tosAccepted,
    }
  }

  public static fromApplication (applicationDto: UserApplicationDto): UserProviderUserDto {
    const dto = UserProviderUserDtoTranslator.publicFromApplication(applicationDto)

    return {
      ...dto,
      email: applicationDto.email,
    }
  }
}
