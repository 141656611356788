import Head from 'next/head'
import Router from 'next/router'
import Script from 'next/script'
import type { AppProps } from 'next/app'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { ThirdwebProvider, embeddedWallet, metamaskWallet, coinbaseWallet } from '@thirdweb-dev/react'
import { CronosTestnet, Polygon, PolygonAmoyTestnet } from '@thirdweb-dev/chains'
import * as snippet from '@segment/snippet'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { environment } from '~/constants/environment'
import { thirdwebClientId } from '~/constants/thirdweb'
import { AppMenu } from '~/components/AppMenu/AppMenu'
import { AppFooter } from '~/components/AppFooter/AppFooter'
import { Settings } from 'luxon'
import { localeWithTerritory } from '~/modules/Shared/Domain/Helpers/Locale'
import { SessionProvider } from 'next-auth/react'
import { SegmentIdentify } from '~/modules/Analytics/Infrastructure/Components/SegmentIdentify'
import UserProvider from '~/modules/Auth/Infrastructure/Components/UserProvider'
import { UserTosModal } from '~/modules/Auth/Infrastructure/Components/UserTosModal'
import '~/styles/globals.scss'
import styles from '~/styles/pages/_app.module.scss'

config.autoAddCss = false

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any
  }
}

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', (_url) => {
  window.analytics.page()
})

function renderSegmentSnippet (): string {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    page: true,
  }

  return snippet.min(opts)
}

function TrophyApp ({
  Component,
  pageProps: {
    session,
    ...pageProps
  },
}: AppProps) {
  const { i18n } = useTranslation()

  Settings.defaultLocale = localeWithTerritory(i18n.language || 'en')
    .replace('_', '-')

  // TODO: find the way to get user timezone and set it on Luxon Settings
  Settings.defaultZone = 'Europe/Madrid'

  return (
    <SessionProvider session={ session }>
      <UserProvider>
        <ThirdwebProvider
          clientId={ thirdwebClientId }
          activeChain={ environment === 'production' ? Polygon : PolygonAmoyTestnet }
          supportedChains={ environment === 'production' ? [Polygon] : [PolygonAmoyTestnet, CronosTestnet] }
          supportedWallets={ [
            embeddedWallet(),
            metamaskWallet(),
            coinbaseWallet(),
          ] }
        >
          <>
            <Script
              id="segment-script"
              dangerouslySetInnerHTML={ { __html: renderSegmentSnippet() } }
            />

            <SegmentIdentify />

            <Head>
              <link rel="icon" href="/favicon.ico" />
            </Head>

            <UserTosModal />

            <AppMenu />

            <main className={ styles.app__container }>
              <Component { ...pageProps } />
            </main>

            <AppFooter />
          </>
        </ThirdwebProvider>
      </UserProvider>
    </SessionProvider>
  )
}

export default appWithTranslation(TrophyApp)
